import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChakraProvider, Box, VStack, Grid, theme, useToast, HStack, Button, Heading, Text, Select, Input, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import axios from 'axios';
import RealTimeChart from './RealTimeChart';

const BACKEND_URL = 'http://10.240.246.218:5000';

function App() {
  const [tradingMode, setTradingMode] = useState('paper');
  const [accountBalance, setAccountBalance] = useState({ BTC: 0, ETH: 0, USDT: 10000 });
  const [realTimePrices, setRealTimePrices] = useState({ BTC: 0, ETH: 0 });
  const [trades, setTrades] = useState([]);
  const [openTrades, setOpenTrades] = useState([]);
  const [dataPoints, setDataPoints] = useState([]);
  const [backtestResults, setBacktestResults] = useState(null);
  const [openTradesCount, setOpenTradesCount] = useState(0);
  const [profitLoss, setProfitLoss] = useState(0);
  const [orderForm, setOrderForm] = useState({ type: 'buy', symbol: 'BTC', amount: 0 });
  const [activeOrders, setActiveOrders] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  const eventSourceRef = useRef(null);
  const toast = useToast();

  const toggleTradingMode = () => {
    setTradingMode(prevMode => prevMode === 'paper' ? 'real' : 'paper');
  };

  const addTrade = (trade) => {
    setTrades(prevTrades => [...prevTrades, trade]);
    setAccountBalance(prevBalance => {
      const newBalance = { ...prevBalance };
      if (trade.type === 'buy') {
        newBalance[trade.symbol] = (newBalance[trade.symbol] || 0) + trade.amount / trade.price;
        newBalance.USDT -= trade.amount;
      } else {
        newBalance[trade.symbol] = (newBalance[trade.symbol] || 0) - trade.amount / trade.price;
        newBalance.USDT += trade.amount;
      }
      return newBalance;
    });
    setOpenTrades(prevOpenTrades => [...prevOpenTrades, trade]);
    updateProfitLoss();
    updateOpenTradesCount();
  };

  const updateProfitLoss = useCallback(() => {
    const totalProfitLoss = openTrades.reduce((total, trade) => {
      const currentPrice = realTimePrices[trade.symbol] || trade.price;
      const tradeAmount = trade.amount / trade.price; // Amount of crypto bought/sold
      const profitLoss = trade.type === 'buy'
        ? (currentPrice - trade.price) * tradeAmount
        : (trade.price - currentPrice) * tradeAmount;
      return total + profitLoss;
    }, 0);
    setProfitLoss(totalProfitLoss.toFixed(2));
  }, [openTrades, realTimePrices]);

  const updateOpenTradesCount = useCallback(() => {
    setOpenTrades(prevOpenTrades => prevOpenTrades.filter(trade => {
      const currentPrice = realTimePrices[trade.symbol] || trade.price;
      return trade.type === 'buy' ? currentPrice < trade.price : currentPrice > trade.price;
    }));
  }, [realTimePrices]);

  const executeTrade = async (order) => {
    if (tradingMode === 'paper') {
      addTrade({
        ...order,
        id: Date.now(),
        price: realTimePrices[order.symbol],
        timestamp: new Date().toISOString(),
      });
    } else {
      try {
        const response = await axios.post(`${BACKEND_URL}/execute_trade`, order);
        addTrade(response.data);
      } catch (error) {
        console.error('Error executing trade:', error);
        toast({
          title: "Trade Execution Failed",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const runBacktest = async () => {
    try {
      const response = await axios.post(`${BACKEND_URL}/run_backtest`);
      setBacktestResults(response.data);
    } catch (error) {
      console.error('Error running backtest:', error);
      toast({
        title: "Backtest Failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const connectSSE = useCallback(() => {
    console.log("Attempting to connect to SSE...");
    console.log("Backend URL:", `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000'}`);
    const eventSource = new EventSource(`${process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000'}/sse`);

    eventSource.onopen = () => {
      console.log("SSE connection opened");
      setConnectionStatus('Connected');
    };

    eventSource.onmessage = (event) => {
      console.log("Received SSE message:", event.data);
      try {
        const data = JSON.parse(event.data);
        console.log("Parsed SSE data:", data);

        setRealTimePrices(prevPrices => {
          const newPrices = {
            ...prevPrices,
            BTC: data.BITCOIN?.price || prevPrices.BTC,
            ETH: data.ETHEREUM?.price || prevPrices.ETH
          };
          console.log("Updated real-time prices:", newPrices);
          return newPrices;
        });

        const newDataPoint = {
          timestamp: new Date().toLocaleTimeString(),
          BTC: parseFloat(data.BITCOIN?.price) || 0,
          ETH: parseFloat(data.ETHEREUM?.price) || 0,
        };

        setDataPoints(prevPoints => {
          const updatedPoints = [...prevPoints, newDataPoint].slice(-20);
          console.log('Updated dataPoints:', JSON.stringify(updatedPoints));
          return updatedPoints;
        });
      } catch (error) {
        console.error("Error parsing SSE data:", error);
      }
    };

    eventSource.onerror = (error) => {
      console.error("SSE connection error:", error);
      setConnectionStatus('Disconnected');
      eventSource.close();
      setTimeout(() => {
        console.log("Attempting to reconnect...");
        connectSSE();
      }, 5000);
    };

    return eventSource;
  }, []);

  useEffect(() => {
    console.log("Setting up SSE connection...");
    const eventSource = connectSSE();
    eventSourceRef.current = eventSource;

    return () => {
      console.log("Cleaning up SSE connection...");
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, [connectSSE]);

  return (
    <ChakraProvider theme={theme}>
      <Box>
        {/* Navigation */}
        <HStack as="nav" spacing={4} p={4} bg="gray.100">
          <Button>Dashboard Overview</Button>
          <Button>Simulated Trading</Button>
          <Button>Real-Time Trading</Button>
          <Button>Backtesting</Button>
        </HStack>

        {/* Main Content */}
        <Box p={4}>
          <VStack spacing={4} align="stretch">
            {/* Trading Mode Toggle */}
            <Box>
              <Button onClick={toggleTradingMode}>
                {tradingMode === 'paper' ? 'Switch to Real Trading' : 'Switch to Paper Trading'}
              </Button>
              <Text>Current Mode: {tradingMode === 'paper' ? 'Paper Trading' : 'Real Trading'}</Text>
            </Box>

            {/* Trading Summary */}
            <Box borderWidth={1} borderRadius="lg" p={4}>
              <Heading size="md">Trading Summary</Heading>
              <Text>Account Balances: BTC: {accountBalance.BTC.toFixed(8)}, ETH: {accountBalance.ETH.toFixed(8)}, USDT: {accountBalance.USDT.toFixed(2)}</Text>
              <Text>Current Profit/Loss: ${profitLoss}</Text>
              <Text>Open Trades: {openTrades.length}</Text>
              <Text>Real-Time Prices: BTC: ${realTimePrices.BTC?.toFixed(2)}, ETH: ${realTimePrices.ETH?.toFixed(2)}</Text>
            </Box>

            {/* Graph Section */}
            <Box borderWidth={1} borderRadius="lg" p={4} h="400px" w="100%">
              <Heading size="md">Graph Section</Heading>
              <Text>Connection Status: {connectionStatus}</Text>
              <Box h="300px">
                {console.log('Rendering RealTimeChart with dataPoints:', JSON.stringify(dataPoints))}
                <RealTimeChart dataPoints={dataPoints} />
              </Box>
            </Box>

            {/* Trade Management */}
            <Box borderWidth={1} borderRadius="lg" p={4}>
              <Heading size="md">Trade Management</Heading>
              <Tabs>
                <TabList>
                  <Tab>Order Form</Tab>
                  <Tab>Active Orders</Tab>
                  <Tab>Trade History</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Box as="form" onSubmit={(e) => {
                      e.preventDefault();
                      executeTrade(orderForm);
                    }}>
                      <Select value={orderForm.type} onChange={(e) => setOrderForm({...orderForm, type: e.target.value})}>
                        <option value="buy">Buy</option>
                        <option value="sell">Sell</option>
                      </Select>
                      <Select value={orderForm.symbol} onChange={(e) => setOrderForm({...orderForm, symbol: e.target.value})}>
                        <option value="BTC">BTC</option>
                        <option value="ETH">ETH</option>
                      </Select>
                      <Input type="number" value={orderForm.amount} onChange={(e) => setOrderForm({...orderForm, amount: parseFloat(e.target.value)})} />
                      <Button type="submit">Place {tradingMode === 'paper' ? 'Simulated' : 'Live'} Trade</Button>
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <VStack align="stretch">
                      {activeOrders.map(order => (
                        <Box key={order.id} p={2} borderWidth={1} borderRadius="md">
                          <Text>Type: {order.type}</Text>
                          <Text>Amount: ${order.amount}</Text>
                          <Text>Price: ${order.price}</Text>
                        </Box>
                      ))}
                    </VStack>
                  </TabPanel>
                  <TabPanel>
                    <VStack align="stretch">
                      {trades.map(trade => (
                        <Box key={trade.id} p={2} borderWidth={1} borderRadius="md">
                          <Text>Type: {trade.type}</Text>
                          <Text>Amount: ${trade.amount}</Text>
                          <Text>Price: ${trade.price}</Text>
                          <Text>Timestamp: {new Date(trade.timestamp).toLocaleString()}</Text>
                        </Box>
                      ))}
                    </VStack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>

            {/* Backtesting View */}
            <Box borderWidth={1} borderRadius="lg" p={4}>
              <Heading size="md">Backtesting</Heading>
              <VStack spacing={4} align="stretch">
                <Button onClick={runBacktest}>Run Backtest</Button>
                <Box>
                  <Heading size="sm">Performance Metrics</Heading>
                  <Text>Profit: ${backtestResults?.profit || 0}</Text>
                  <Text>Win/Loss Ratio: {backtestResults?.winLossRatio || 0}</Text>
                  <Text>Max Drawdown: {backtestResults?.maxDrawdown || 0}%</Text>
                </Box>
                <Box h="300px">
                  <Heading size="sm">Historical Data</Heading>
                  {/* TODO: Implement custom chart for historical data */}
                  <Text>Graph: Historical data with buy/sell markers and performance summary</Text>
                </Box>
              </VStack>
            </Box>
          </VStack>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;
