import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const RealTimeChart = ({ dataPoints }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={dataPoints}>
        <XAxis dataKey="timestamp" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend />
        <Line yAxisId="left" type="monotone" dataKey="BTC" stroke="#8884d8" dot={false} name="BTC" />
        <Line yAxisId="right" type="monotone" dataKey="ETH" stroke="#82ca9d" dot={false} name="ETH" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default RealTimeChart;
